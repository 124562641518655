<template>
  <div class="pop-box">
    <van-nav-bar title="白名单详情" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="pop-list" v-if="params.type == 1">
      <div class="pop-item">
        <div class="item-label">营业执照照片</div>
        <div class="item-value">
          <img style="width:140px;height:100px" :src="params.businessLicensePath" alt />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">类型</div>
        <div class="item-value">
          <div class="item-btn">企业</div>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">名称</div>
        <div class="item-value">{{params.name}}</div>
      </div>
      <div class="pop-item">
        <div class="item-label">证件号</div>
        <div class="item-value">{{params.certificateNo}}</div>
      </div>
      <div class="pic">
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">法人代表身份证正面</div>
          <div class="item-value">
            <img style="width:140px;height:100px" :src="params.idCardFrontPath" alt />
          </div>
        </div>
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">法人代表身份证背面</div>
          <div class="item-value">
            <img style="width:140px;height:100px" :src="params.idCardBackPath" alt />
          </div>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">法人名称</div>
        <div class="item-value">{{params.legalPersonName}}</div>
      </div>
      <div class="pop-item">
        <div class="item-label">法人代表证件号</div>
        <div class="item-value">{{params.legalPersonIdNo}}</div>
      </div>
      <div class="pop-item">
        <div class="item-label">联系电话</div>
        <div class="item-value" v-show="params.phone">{{params.phone}}</div>
        <div class="item-value" v-show="!params.phone">-</div>
      </div>

      <div class="pop-item">
        <div class="item-label">是否生效</div>
        <div class="item-value" v-show="params.isTakeEffect == 1">已生效</div>
        <div class="item-value" v-show="!params.isTakeEffect">未生效</div>
      </div>
    </div>
    <div class="pop-list" v-if="params.type == 2">
      <div class="pic">
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">身份证正面</div>
          <div class="item-value">
            <img style="width:140px;height:100px" :src="params.idCardFrontPath" alt />
          </div>
        </div>
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">身份证背面</div>
          <div class="item-value">
            <img style="width:140px;height:100px" :src="params.idCardBackPath" alt />
          </div>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">类型</div>
        <div class="item-value">
          <div class="item-btn">个人</div>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">名称</div>
        <div class="item-value">{{params.name}}</div>
      </div>
      <div class="pop-item">
        <div class="item-label">证件号</div>
        <div class="item-value">{{params.certificateNo}}</div>
      </div>
      <div class="pop-item">
        <div class="item-label">联系电话</div>
        <div class="item-value" v-show="params.phone">{{params.phone}}</div>
        <div class="item-value" v-show="!params.phone">-</div>
      </div>
      <div class="pop-item">
        <div class="item-label">是否生效</div>
        <div class="item-value" v-show="params.isTakeEffect == 1">已生效</div>
        <div class="item-value" v-show="!params.isTakeEffect">未生效</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      params: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.params = this.$store.state.details;
    this.params.idCardFrontPath = `${this.$store.state.api}/PicController/showImg.do?filename=${this.params.idCardFrontPath}`;
    this.params.idCardBackPath = `${this.$store.state.api}/PicController/showImg.do?filename=${this.params.idCardBackPath}`;
    this.params.businessLicensePath = `${this.$store.state.api}/PicController/showImg.do?filename=${this.params.businessLicensePath}`;
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.pop-list {
  padding: 10px 20px;
}
.pop-item {
  display: flex;
  font-size: 16px;
  align-items: center;
  margin: 10px 0;
}
.item-label {
  color: #666666;
  width: 130px;
}
.item-value {
  color: #333333;
}

.items > .item-label {
  width: 170px;
}
.pic {
  display: flex;
}
.items {
  display: block;
}
.items img {
  margin-top: 10px;
}
</style>