<template>
  <div class="container">
    <div class="logo">
      <img src="../assets/logo418.png" alt />
      <div>全国商用车行业指数平台</div>
    </div>
    <div class="login">
      <div class="phone">
        <img src="../assets/phone.png" alt />
        <input type="text" v-model.trim="phone" style="width:240px" placeholder="请输入手机号" />
      </div>
      <div class="code">
        <img src="../assets/code.png" alt />
        <input type="text" v-model.trim="code" placeholder="请输入验证码" />
        <div class="getCode" @click="getCode">{{text}}</div>
      </div>
    </div>
    <div class="loginBtn" @click="login">登录</div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getCode, loginAdmin } from "@/utils/http";
import { setCookie } from "@/utils/cookie";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      text: "发送验证码",
      time: "",
      currentTime: 60,
      phone: "",
      code: "",
      codeStatus: true,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    //发送验证码
    async getCode() {
      if (!this.codeStatus) return;
      let that = this;
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      const { data: res } = await getCode(this.phone);
      console.log(res);
      if (!res.success) {
        Toast(res.info);
        return;
      }
      if (res.success) {
        Toast(res.info);
      }
      that.codeStatus = false;
      var interval = setInterval(function () {
        that.currentTime--; //每执行一次让倒计时秒数减一
        that.text = that.currentTime + "s";
        if (that.currentTime <= 0) {
          clearInterval(interval);
          that.text = "重新发送";
          that.currentTime = 60;
          that.codeStatus = true;
        }
      }, 1000);
    },
    async login() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      if (!this.code) {
        Toast("请输入验证码");
        return;
      }
      let params = {
        phone: this.phone,
        smsCode: this.code,
      };
      const { data: res } = await loginAdmin(params);
      console.log(res);
      if (!res.success) {
        Toast(res.info);
        return;
      }
      Toast(res.info);
      localStorage.setItem("loginTime", new Date().getTime());
      localStorage.setItem("token", res.data);
      setCookie("loginTime", new Date().getTime(), 2);
      setCookie("token", res.data, 2);
      this.$store.commit("set_source", 0);
      this.$router.push("/WhiteList");
    },
  },
};
</script>
<style  scoped>
.container {
  height: 100vh;
  background: #ffffff;
}
.logo {
  width: 100%;
  text-align: center;
  font-size: 22px;
}
.logo > img {
  border-radius: 10%;
  margin: 30px 0;
}
.login {
  margin: 40px;
}
.login > div {
  display: flex;
  width: 300px;
  height: 45px;
  background: #f7f7f7;
  border-radius: 23px;
  align-items: center;
}
.login > div > input {
  border: none;
  background: none;
  font-size: 16px;
  height: 45px;
}
.login > div > img {
  width: 18px;
  height: 22px;
  margin: 0 20px;
}
.code {
  margin-top: 20px;
}
.code > input {
  width: 130px;
}
.getCode {
  width: 94px;
  font-size: 16px;
  font-weight: 400;
  color: #0378eb;
  text-align: right;
}
.loginBtn {
  width: 280px;
  line-height: 50px;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  box-shadow: 0px 2px 6px 0px #69abff;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
}
</style>