<template>
  <div class="container">
    <div class="position">
      <van-nav-bar title="白名单管理" />
      <!-- <div class="search-top">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item v-model="value" :options="option" @change="change" />
        </van-dropdown-menu>
        <div class="lines"></div>
        <input
          type="text"
          v-if="value == 0"
          v-model.trim="name"
          @keyup.enter="search"
          placeholder="请输入名称"
        />
        <input
          type="text"
          v-if="value == 1"
          v-model.trim="code"
          @keyup.enter="search"
          placeholder="请输入证件号码"
        />
        <div class="lines"></div>
        <div class="search-btns" @click="search">
          <van-icon name="search" color="#666666" />
        </div>
      </div>-->
      <!-- <div class="search">
        <div class="li">
          <div class="li-left">来源：</div>
          <div class="sourceList">
            <div :class=" source == 0 ? 'active' : ''" @click="getTypeList(0)">全部</div>
            <div :class=" source == 1 ? 'active' : ''" @click="getTypeList(1)">添加</div>
            <div :class=" source == 2 ? 'active' : ''" @click="getTypeList(2)">渠道</div>
          </div>
        </div>
      </div>-->
    </div>
    <div class="btnList">
      <div class="creates" @click="createInfo">新建</div>
      <div class="edit">编辑</div>
    </div>
    <!-- <div class="create">
      <img @click="createInfo" src="../../assets/create.png" alt />
    </div>-->
    <!-- 内容 -->
    <div class="content" @scroll="hanldeScroll" id="content">
      <div class="content-list" id="content-list" v-for="(item,index) in list" :key="index">
        <div class="list">
          <div class="list-label">名称</div>
          <div class="list-value">{{item.name}}</div>
        </div>
        <div class="list">
          <div class="list-label">证件号</div>
          <div class="list-value">{{item.certificateNo}}</div>
        </div>
        <div class="list">
          <div class="list-label">类型</div>
          <div class="list-value" v-show="item.type == 1">企业</div>
          <div class="list-value" v-show="item.type == 2">个人</div>
        </div>
        <div class="list" v-show="item.legalPersonName">
          <div class="list-label">法人名称</div>
          <div class="list-value">{{item.legalPersonName}}</div>
        </div>
        <div class="list" v-show="item.legalPersonIdNo">
          <div class="list-label">法人证件号</div>
          <div class="list-value">{{item.legalPersonIdNo}}</div>
        </div>
        <div class="list">
          <div class="list-label">联系电话</div>
          <div class="list-value" v-show="item.phone">{{item.phone}}</div>
          <div class="list-value" v-show="!item.phone">-</div>
        </div>
        <div class="list">
          <div class="list-label">状态</div>
          <div class="list-value" v-show="item.isTakeEffect == 1">生效</div>
          <div class="list-value" v-show="item.isTakeEffect == 0">未生效</div>
        </div>
        <div class="list">
          <div class="list-label">来源</div>
          <div class="list-value" v-show="item.source == 1">添加</div>
          <div class="list-value" v-show="item.source == 2">渠道</div>
        </div>
        <div class="list">
          <div class="list-label">更新时间</div>
          <div class="list-value">{{item.updateTime}}</div>
        </div>
        <div class="operation">
          <div class="details" style="margin-right:20px" @click="jumpDetails(item)">查看详情</div>
          <!-- <div class="details delete" @click="remove(item.whiteListId)">删除</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getInfoList, removeInfo } from "@/utils/http";
import { Toast, Dialog } from "vant";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      details: true,
      name: "",
      code: "",
      source: 0,
      value: 0,
      option: [
        { text: "名称", value: 0 },
        { text: "证件号码", value: 1 },
      ],
      list: [],
      pageNum: 1,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.source = this.$store.state.source;
    // this.getList();
  },
  activated() {
    document.querySelector(".content").scrollTop =
      sessionStorage.getItem("scrollTop") || 0;
  },
  methods: {
    getTypeList(e) {
      this.source = e;
      this.pageNum = 1;
      this.getList();
    },
    jumpDetails(item) {
      this.$store.commit("details", item);
      this.$store.commit("set_source", this.source);
      let listScrollTop = document.querySelector(".content").scrollTop;
      sessionStorage.setItem("scrollTop", listScrollTop);
      this.$router.push("/details");
    },
    createInfo() {
      this.$router.push("/create");
    },
    search() {
      this.pageNum = 1;
      this.getList();
    },
    remove(id) {
      Dialog.alert({
        title: "温馨提示",
        message: "确定要删除数据吗？",
        showCancelButton: true,
        confirmButtonColor: "#0378eb",
      }).then(async () => {
        const { data: res } = await removeInfo({ id });
        if (!res.success) {
          Toast(res.info);
          return;
        }
        Toast(res.info);
        this.name = "";
        this.code = "";
        this.pageNum = 1;
        this.getList();
      });
    },
    change(v) {
      this.name = "";
      this.code = "";
      this.pageNum = 1;
      this.getList();
    },
    async getList() {
      let params = {
        // _search: false,
        // _tnd: 1670406493274,
        rp: 30,
        page: this.pageNum,
        sortname: "whiteListId",
        sortorder: "asc",
      };
      if (this.source) {
        params.source = this.source;
      }
      if (this.name) {
        params.name = this.name;
      }
      if (this.code) {
        params.certificateNo = this.code;
      }
      const { data: res } = await getInfoList(params);

      if (this.pageNum == 1) {
        if (!res.rows.length) {
          Toast("暂无数据");
          return;
        }
        this.list = res.rows;
      } else {
        if (!res.rows.length) {
          Toast("数据已全部加载完毕");
          return;
        }
        let list = this.list;
        this.list = list.concat(res.rows);
      }
    },
    hanldeScroll(e) {
      let conScrollHeight = e.target.scrollHeight; // 可以滚动区域的高度
      let conClientHeight = e.target.clientHeight; // 区域内容的高度
      let conScrollTop = e.target.scrollTop; //  内容滚动了的高度
      // 内容滚动了的高度 +  区域内容的高度 >= 可以滚动区域的高度
      // 则证明滑动到了页面底部，这个时候就去处理加载更多的逻辑)
      if (conScrollTop + conClientHeight >= conScrollHeight - 1) {
        // this.list.push({ id: this.list.length + 1 });
        this.pageNum = this.pageNum + 1;
        this.getList();
      }
    },
  },
};
</script>
<style scope>
::-webkit-scrollbar {
  display: none;
}
.container {
  background: #fff;
  /* background: #f8f8f8; */
}
.title {
  font-size: 20px;
  text-align: center;
}
.search {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 20px;
}
.li {
  display: flex;
  font-size: 16px;
  margin: 10px 0;
  align-items: center;
}
.li .li-left {
  width: 80px;
}
.li input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 22px;
}
.msg {
  display: flex;
  width: 60px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
}

.position {
  background: #ffffff;
  padding-bottom: 5px;
}
.content {
  /* height: 700px; */
  margin-top: 10px;
  overflow: scroll;
}
.content-list {
  background: #ffffff;
  padding-top: 10px;
  margin-bottom: 10px;
}
.content-list > .list:nth-of-type(1) {
  margin: 0;
}
.list {
  display: flex;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 10px;
  align-items: center;
}
.list-label {
  width: 100px;
  color: #666666;
}
.list-value {
  color: #333333;
}
.operation {
  margin-top: 10px;
  height: 60px;
  display: flex;
  border-top: 1px solid #ccc;
  align-items: center;
  justify-content: flex-end;
}
.details {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  font-size: 16px;
  line-height: 40px;
}
.delete {
  margin: 0 20px;
}
.sourceList {
  width: 180px;
  display: flex;
  justify-content: space-between;
}
.sourceList > div {
  padding: 5px;
  border-radius: 2px;
}
.active {
  color: #ffffff;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
}
.create {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 60%;
  right: 1%;
}
.create > img {
  width: 100%;
  height: 100%;
}
.van-dropdown-menu {
  width: 80px;
}
.van-dropdown-menu__item,
.van-dropdown-menu__bar {
  width: 80px;
  height: 40px;
  margin: 0;
  padding: 0;
}
.van-dropdown-menu__title {
  padding: 0;
  padding-right: 8px;
}
.van-dropdown-menu__bar {
  box-shadow: none;
}
.van-nav-bar__content {
  border-bottom: 1px solid #cccccc;
}
.search-top {
  width: 350px;
  display: flex;
  align-items: center;

  margin: 0 auto;
  margin-top: 10px;
}
.search-top > input {
  width: 200px;
  border: none;
  /* border: 1px solid #cccccc; */
  border-radius: 4px;
  height: 30px;
  font-size: 16px;
  text-indent: 10px;
}
.btn {
  font-size: 16px;
  line-height: 30px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
}
.lines {
  width: 1px;
  height: 24px;
  border-left: 1px solid #cccccc;
  margin: 0 5px;
}
.search-btns {
  width: 70px;
  text-align: center;
}
.btnList {
  display: flex;
  position: absolute;
  top: 50%;
  width: 100%;
  justify-content: space-around;
}
.creates {
  width: 130px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
}
.edit {
  width: 130px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  /* background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%); */
  background: #ccc;
}
</style>