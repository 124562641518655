import Request from "./requset.js";
import store from "@/store/index";
let baseUrl = store.state.api;
export function uploads(
  url,
  params = {},
  headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    // Accept: "multipart/form-data",
  }
) {
  return new Promise((resolve, reject) => {
    // console.log(params)
    Request({
      url: url,
      method: "POST",
      data: params,
      headers: headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// get方法
export function get(val) {
  return Request({
    method: "get",
    url: val.apiUrl,
    params: val.params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// post方法
export function post(val) {
  return Request({
    method: "POST",
    url: val.apiUrl,
    data: val.params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
// ocr
export const ocr = (params) =>
  uploads(`${baseUrl}/common/PicController/uploadMultiImgs.do`, params);
// 识别信息
export const getPicInfo = (params) =>
  get({
    apiUrl: `${baseUrl}/ocr/trans.do?imgClass=${params.key}&imgUrl=/common/PicController/showImg.do?filename=${params.val}`,
  });
// 验证码
export const getCode = (params) =>
  get({
    apiUrl: `${baseUrl}/login/sendSmsCode?phone=${params}`,
  });
// 登录
export const loginAdmin = (params) =>
  post({
    apiUrl: `${baseUrl}/login/loginBySmsCode`,
    params,
  });
// 获取列表
export const getInfoList = (params) =>
  post({
    apiUrl: `${baseUrl}/JobWhiteListController/query.do`,
    params,
  });
// 添加
export const addInfo = (params) =>
  post({
    apiUrl: `${baseUrl}/JobWhiteListController/add.do`,
    params,
  });
// 删除
export const removeInfo = (params) =>
  post({
    apiUrl: `${baseUrl}/JobWhiteListController/delete.do`,
    params,
  });

