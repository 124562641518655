<template>
  <div class="pop-box">
    <van-nav-bar title="添加白名单" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="pop-btn">
      <div :class="type == 1?'active item-btn' : 'item-btn'" @click="type=1,status=1">企业</div>
      <div :class="type == 2?'active item-btn' : 'item-btn'" @click="type=2,status=1">个人</div>
    </div>
    <div class="pop-list" v-show="type == 1">
      <div class="pop-item">
        <div class="item-label" style="margin-right:10px">营业执照照片</div>
        <div class="item-value">
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :after-read="(file) => afterRead(file, 1)"
          >
            <img class="load" src="../../assets/load.png" alt />
          </van-uploader>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">名称</div>
        <div class="item-value">
          <input type="text" v-model.trim="params.title" placeholder="请输入名称" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">证件号</div>
        <div class="item-value">
          <input type="text" v-model.trim="params.idCode" placeholder="请输入证件号" />
        </div>
      </div>
      <div class="pic">
        <div class="pop-item items">
          <div class="item-label">法人代表身份证正面</div>
          <div class="item-value">
            <van-uploader
              v-model="fileList1"
              :max-count="1"
              :after-read="(file) => afterRead(file, 2)"
            >
              <img class="load" src="../../assets/is.png" alt />
            </van-uploader>
          </div>
        </div>
        <div class="pop-item items">
          <div class="item-label">法人代表身份证背面</div>
          <div class="item-value">
            <van-uploader
              v-model="fileList2"
              :max-count="1"
              :after-read="(file) => afterRead(file, 3)"
            >
              <img class="load" src="../../assets/false.png" alt />
            </van-uploader>
          </div>
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">法人名称</div>
        <div class="item-value">
          <input type="text" v-model.trim="params.userName" placeholder="请输入法人名称" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">法人证件号</div>
        <div class="item-value">
          <input type="text" v-model.trim="params.idNum" maxlength="18" placeholder="请输入法人证件号" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">联系电话</div>
        <div class="item-value">
          <input type="text" v-model.trim="params.phone" placeholder="请输入联系电话" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">是否生效</div>
        <div class="item-value item-values">
          <div
            class="status"
            :class="status == 1?'act' :''"
            style="margin-right:10px"
            @click="status = 1"
          >生效</div>
          <div class="status" :class="!status ?'act ' :''" @click="status=''">不生效</div>
        </div>
      </div>
    </div>
    <div class="pop-list" v-show="type == 2">
      <div class="pic">
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">身份证正面</div>
          <div class="item-value">
            <van-uploader
              v-model="fileList1"
              :max-count="1"
              :after-read="(file) => afterRead(file, 2)"
            >
              <img class="load" src="../../assets/is.png" alt />
            </van-uploader>
          </div>
        </div>
        <div class="pop-item items" style="text-align:center">
          <div class="item-label">身份证背面</div>
          <div class="item-value">
            <van-uploader
              v-model="fileList2"
              :max-count="1"
              :after-read="(file) => afterRead(file, 3)"
            >
              <img class="load" src="../../assets/false.png" alt />
            </van-uploader>
          </div>
        </div>
      </div>
      <div class="pop-item" style="margin-top:20px;">
        <div class="item-label">名称</div>
        <div class="item-value">
          <input type="text" v-model.trim="sing.userName" placeholder="请输入名称" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">证件号</div>
        <div class="item-value">
          <input type="text" v-model.trim="sing.idNum" maxlength="18" placeholder="请输入证件号" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">联系电话</div>
        <div class="item-value">
          <input type="text" v-model.trim="sing.phone" placeholder="请输入联系电话" />
        </div>
      </div>
      <div class="pop-item">
        <div class="item-label">是否生效</div>
        <div class="item-value item-values">
          <div
            class="status"
            :class="status == 1?'act' :''"
            style="margin-right:10px"
            @click="status = 1"
          >生效</div>
          <div class="status" :class="!status ?'act ' :''" @click="status=''">不生效</div>
        </div>
      </div>
    </div>
    <div class="confirm" @click="confirm">确定</div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { ocr, getPicInfo, addInfo } from "@/utils/http"; //路径注意
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      fileList: [],
      fileList1: [],
      fileList2: [],
      type: 1,
      status: 1,
      params: {
        title: "",
        idCode: "",
        business: "",
        idCard: "",
        isCard: "",
        userName: "",
        idNum: "",
        phone: "",
      },
      sing: {
        userName: "",
        idNum: "",
        phone: "",
        idCard: "",
        isCard: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async afterRead(file, key) {
      const params = new FormData();
      params.append("file", file.file);
      // Toast("ocr开始");
      const { data: res } = await ocr(params);
      console.log(res.data);
      let active = "40289fe0-7084be65-0170-84bee7a9-0001";
      let type = "sfzzm";
      // 身份证正面
      if (res.data) {
        // Toast("ocr成功");
        if (key == 1) {
          active = "ff808081719c62b601719c729b8c0001";
          type = "yyzz";
        }
        if (key == 3) {
          active = "40289fe0-7084be65-0170-84bf1d63-0002";
          type = "sfzbm";
        }
        await this.uploadPic(active, type, res.data, key);
      }
    },
    async uploadPic(key, type, val, act) {
      // Toast("ocr信息开始接收");
      let params = {
        key,
        type,
        val,
      };
      const { data: res } = await getPicInfo(params);
      if (!res.success) {
        Toast(res.info);
        return;
      }
      // Toast("ocr信息返回");
      if (this.type == 1) {
        // 企业
        if (act == 1) {
          this.params.title = res.data.Name;
          this.params.idCode = res.data.RegNum;
          this.params.business = val;
        }
        if (act == 2) {
          this.params.userName = res.data.Name;
          this.params.idNum = res.data.IdNum;
          this.params.idCard = val;
        }
        if (act == 3) {
          this.params.isCard = val;
        }
      }
      if (this.type == 2) {
        // 个人
        if (act == 2) {
          this.sing.userName = res.data.Name;
          this.sing.idNum = res.data.IdNum;
          this.sing.idCard = val;
        }
        if (act == 3) {
          this.sing.isCard = val;
        }
      }
      console.log(res);
    },
    async confirm() {
      let params = {
        type: this.type,
        isTakeEffect: this.status,
        whiteListld: "",
      };
      if (this.type == 1) {
        params.name = this.params.title;
        params.certificateNo = this.params.idCode;
        params.businessLicensePath = this.params.business[0];
        params.legalPersonName = this.params.userName;
        params.legalPersonIdNo = this.params.idNum;
        params.idCardFrontPath = this.params.idCard[0];
        params.idCardBackPath = this.params.isCard[0];
        params.phone = this.params.phone;
        if (!params.businessLicensePath) {
          Toast("请上传营业执照");
          return;
        }
        if (!params.name) {
          Toast("请输入名称");
          return;
        }
        if (!params.certificateNo) {
          Toast("请输入证件号");
          return;
        }
        if (!params.legalPersonName) {
          Toast("请输入法人名称");
          return;
        }
        if (!params.legalPersonIdNo) {
          Toast("请输入法人证件号");
          return;
        }
      } else {
        params.name = this.sing.userName;
        params.certificateNo = this.sing.idNum;
        params.idCardFrontPath = this.sing.idCard[0];
        params.idCardBackPath = this.sing.isCard[0];
        params.phone = this.sing.phone;
        if (!params.name) {
          Toast("请输入姓名");
          return;
        }
        if (!params.certificateNo) {
          Toast("请输入证件号码");
          return;
        }
      }
      if (!params.idCardFrontPath) {
        Toast("请上传身份证正面");
        return;
      }
      if (!params.idCardBackPath) {
        Toast("请上传身份证背面");
        return;
      }
      if (!params.phone) {
        Toast("请输入联系方式");
        return;
      }
      const { data: res } = await addInfo(params);
      if (!res.success) {
        Toast(res.info);
        return;
      }
      Toast(res.info);
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.pop-list {
  padding: 10px 20px;
}
.pop-item {
  display: flex;
  font-size: 16px;
  align-items: center;
  margin: 10px 0;
}
.item-label {
  color: #666666;
  width: 100px;
}
.item-value {
  color: #333333;
}

.items > .item-label {
  width: 170px;
}
.pic {
  display: flex;
}
.items {
  display: block;
}
.items > .item-label {
  margin-bottom: 10px;
}
.items > .item-value {
  margin-left: 20px;
}
.load {
  width: 102px;
  height: 80px;
}
.pop-btn {
  display: flex;
  height: 50px;
  font-size: 20px;
}
.item-btn {
  flex: 1;
  line-height: 50px;
  text-align: center;
  background: #f2f2f2;
}
.active {
  color: #0378eb;
  background: #deedff;
  font-weight: bold;
}
.item-value > input {
  border: none;
  width: 230px;
}
.item-values {
  display: flex;
}
.status {
  width: 90px;
  line-height: 30px;
  background: #f2f2f2;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}
.act {
  color: #0378eb;
  background: #deedff;
  font-weight: bold;
}
.confirm {
  width: 260px;
  line-height: 40px;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  box-shadow: 0px 2px 10px 0px #79b4ff;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin: 20px auto;
}
</style>