import axios from "axios";
import store from "@/store/index";
// post请求使用
const request = axios.create({
  // baseURL: "/api", //春雨线上接口
  timeout: 60000,
});
request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") || "";
    // const token = store.state.token || "";
    config.headers.token = token;
    // config.headers.Content-Type = 'application/x-www-form-urlencoded'

    store.commit("showLoading");
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
request.interceptors.response.use(
  (response) => {
    store.commit("hideLoading");
    return response;
  },
  (err) => {
    const { response } = err;
    if (response) {
      switch (response.status) {
        case 400:
          console.log(response.data);
          break;
        case 401:
          console.log(response.data);
          break;
        case 403:
          console.log(response.data);
          break;
        case 404:
          console.log(response.data);
          break;
        default:
          console.log(response.data);
          break;
      }
    } else {
      console.log("请求中断了！！！");
    }
    store.commit("hideLoading");
  }
);
let Request = (params) => {
  return new Promise((resolve, reject) => {
    request({
      ...params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default Request;
